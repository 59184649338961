<template>
  <div class="features_container">
    <div class="features_head">
      <h2>Fitur Utama</h2>
      <p>
        Fitur utama CoinX menggabungkan teknologi canggih, keterjangkauan,
        keamanan, dan kemudahan pengguna untuk menciptakan platform pertukaran
        kripto yang tepercaya dan inklusif.
      </p>
    </div>

    <div class="features_bottom">
      <div class="side_left">
        <div class="side_left-top">
          <img src="/images/frame-02.png" alt="freame" />
          <div class="side_item-a">
            <h2>Biaya Rendah</h2>
            <p>
              Salah satu fitur yang luar biasa dari CoinX adalah biaya rendah.
              Ini berarti bahwa pengguna dapat menarik dana tanpa dikenakan
              biaya tambahan, menjadikannya sangat menarik bagi member.
            </p>
          </div>
        </div>

        <div class="side_left-bottom">
          <div class="left_itm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <rect
                x="5.02576"
                y="4.5158"
                width="11.4583"
                height="11.4583"
                rx="5.72917"
                fill="white"
              />
            </svg>
            <span>Aman</span>
          </div>
          <div class="left_itm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <rect
                x="5.02576"
                y="4.5158"
                width="11.4583"
                height="11.4583"
                rx="5.72917"
                fill="white"
              />
            </svg>
            <span>Transparan</span>
          </div>
          <div class="left_itm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <rect
                x="5.02576"
                y="4.5158"
                width="11.4583"
                height="11.4583"
                rx="5.72917"
                fill="white"
              />
            </svg>
            <span>Tepercaya</span>
          </div>
          <div class="left_itm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <rect
                x="5.02576"
                y="4.5158"
                width="11.4583"
                height="11.4583"
                rx="5.72917"
                fill="white"
              />
            </svg>
            <span>Mudah digunakan</span>
          </div>
        </div>
      </div>
      <div class="side_right">
        <img src="/images/rate.png" alt="rate" class="d-none d-md-block" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.features_container {
  background-image: url("/images/feature-bg-02.png");
  background-size: cover;
  /* height: 800px; */
  /* padding-top: 70px; */
  padding: 68px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.features_head {
  max-width: 752px;
}
.features_head h2 {
  text-align: center;
  font-size: 48px;
  margin-bottom: 25px;
  font-weight: bold;
}
.features_head p {
  text-align: center;
  font-size: 20px;
}
.features_bottom {
  max-width: 958px;
  display: flex;
  justify-content: space-between;
  /* gap: 160px; */
  margin-top: 170px;
}
.side_left-top {
  display: flex;
  gap: 20px;
}
.side_left-top img {
  height: 177px;
  width: auto;
}
.side_item-a h2 {
  font-size: 23px;
  margin-bottom: 28px;
  font-weight: bold;
}
.side_item-a p {
  font-size: 14px;
  width: 260px;
}
.left_itm {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.left_itm span {
  font-size: 19.497px;
  font-weight: bold;
}

@media only screen and (max-width: 550px) {
  .features_head h2 {
    font-size: 30px;
  }
  .features_head p {
    font-size: 14px;
  }
  .features_bottom {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .side_right img {
    display: none;
  }
  .features_bottom {
    gap: 160px;
  }
}
</style>
