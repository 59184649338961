<template>
  <div class="bg_container">
    <TopNav />
    <HeroSection />
    <CarauselSection />
    <FeaturesSection />
    <PopularAssets />
    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import MarketTable from "@/components/global/MarketTable.vue";
import HeroSection from "@/components/home/HeroSection.vue";
import CarauselSection from "@/components/home/CarauselSection.vue";
import FeaturesSection from "@/components/home/FeaturesSection.vue";
import PopularAssets from "@/components/home/PopularAssets.vue";

export default {
  components: {
    TopNav,
    Footer,
    MarketTable,
    HeroSection,
    CarauselSection,
    FeaturesSection,
    PopularAssets,
  },

  data() {
    return {
      themain,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    market_update: function (data) {
      var self = this;

      var market = data.market;

      self.emitter.emit("fe-market_update", {
        data: market,
      });
    },
  },

  async beforeMount() {
    var self = this;

    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  mounted() {
    var self = this;

    if (self.token) {
      var selected = document.getElementById("auth_menu_button");
      if (selected) {
        selected.style.visibility = "hidden";
      }
    }
  },
};
</script>

<style scoped>
.bg_container {
  min-height: 100vh;
}
</style>
