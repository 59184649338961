<template>
  <div>
    <div class="markets-pair-list">
      <div class="d_wrapper">
        <div
          @click="handleDigitMenu"
          @mouseleave="isActive = false"
          class="dex_digit"
        >
          <div class="digit_item">
            <span>{{ valueSelected }}</span>
            <svg
              width="15"
              height="7"
              viewBox="0 0 19 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.2195 0L9.10975 10.9317L0 0H18.2195Z"
                fill="aliceblue"
              />
            </svg>
          </div>

          <div class="digit_lists" :class="{ menu_active: isActive }">
            <span @click="changeDigitFloat('0.0001')">0.0001</span
            ><span @click="changeDigitFloat('0.001')">0.001</span
            ><span @click="changeDigitFloat('0.01')">0.01</span
            ><span @click="changeDigitFloat('0.1')">0.1</span
            ><span @click="changeDigitFloat('1')">1</span>
          </div>
        </div>
      </div>
      <table class="table table-wide">
        <thead>
          <tr>
            <!-- <th class="text-left">ID</th> -->
            <th class="text-left">Pair</th>
            <th>Harga</th>
            <th>Perubahan (24J)</th>
            <th>Tertinggi (24J)</th>
            <th>Terendah (24J)</th>
            <th>Volume</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in markets" :key="item.id">
            <!-- <td @click="gotoMarket(item)">#{{ item.id }}</td> -->
            <td @click="gotoMarket(item)">
              <img
                :src="item.currencies[0].ccurrency_icon_data_url"
                style="border-radius: 100%; height: 16px"
              />
              {{ item.pair_show }}
            </td>

            <td
              v-if="item.pair_show.includes('/IDR')"
              :class="`text-right ${item.theclass}`"
            >
              {{
                parseFloat(item.last_price).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </td>

            <!-- relative float digit -->
            <td v-else :class="`text-right ${item.theclass}`">
              {{ parseFloat(item.last_price).toFixed(floatDigit) }}
            </td>

            <td :class="`text-right ${item.theclass}`">
              {{ parseFloat(item.change_24h).toFixed(2) }} %
            </td>

            <td v-if="item.pair_show.includes('/IDR')" class="text-right">
              {{
                parseFloat(item.high_24h).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </td>

            <!-- relative float digit -->
            <td v-else class="text-right">
              {{ parseFloat(item.high_24h).toFixed(floatDigit) }}
            </td>

            <td v-if="item.pair_show.includes('/IDR')" class="text-right">
              {{
                parseFloat(item.low_24h).toLocaleString("en-US", {
                  minimumFractionDigits: floatDigit,
                })
              }}
            </td>

            <!-- relative float digit -->
            <td v-else class="text-right">
              {{ parseFloat(item.low_24h).toFixed(floatDigit) }}
            </td>

            <td class="text-right">
              {{ parseFloat(item.vol_24h).numberFormat(floatDigit) }}
              {{ item.simbols[1] }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-narrow">
        <table class="table">
          <thead>
            <tr>
              <th class="text-left">Pair</th>
              <th>Harga</th>
              <th>Perubahan (24J)</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in markets" :key="item.id">
              <td>
                <router-link
                  :to="`/markets/${item.id}/${item.pair}`"
                  class="text-light"
                >
                  <img
                    :src="item.currencies[0].ccurrency_icon_data_url"
                    height="16"
                    style="border-radius: 100%"
                  />
                  {{ item.pair_show }}
                </router-link>
              </td>
              <td :class="`text-right ${item.theclass}`">
                {{
                  parseFloat(item.last_price).numberFormat(
                    item.currencies[1].decimal_display
                  )
                }}
              </td>
              <td :class="`text-right ${item.theclass}`">
                {{ parseFloat(item.change_24h).toFixed(2) }} %
              </td>
              <td class="text-right">
                {{ parseFloat(item.vol_24h).numberFormat(2) }}
                {{ item.simbols[1] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import { mapState } from "vuex";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,
      markets: [],

      isActive: false,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    market_update: function (data) {
      var self = this;
      // lakukan perulangan markets
      self.markets.forEach(function (el, i) {
        // console.log("el", el);
        if (el.pair == data.pair) {
          var newData = JSON.parse(data.jsontable);
          // console.log("newData", newData);

          var the_high = 0;
          if (Number.isNaN(el.ticker_24h_high) == false) {
            the_high = el.ticker_24h_high;
          }

          var the_low = 0;
          if (Number.isNaN(el.ticker_24h_low) == false) {
            the_low = el.ticker_24h_low;
          }

          self.markets[i].last_price = newData.last_price;
          self.markets[i].change_24h = newData.change_24h;
          self.markets[i].high_24h = the_high;
          self.markets[i].low_24h = the_low;
          self.markets[i].vol_24h = newData.vol_24h;
        }
      });
    },
  },

  computed: {
    ...mapState(["valueSelected", "floatDigit"]),
  },

  async mounted() {
    var self = this;

    await self.getMarkets();

    self.emitter.on("fe-market_update", async function (e) {
      // console.log("e market-update", e);

      if (e.data) {
        var lamas = self.markets; // tampung data lama
        self.markets = []; // kosongkan markets
        var barus = [];

        lamas.forEach((ele) => {
          if (ele.id == e.data.id) {
            barus.push(e.data);
          } else {
            barus.push(ele);
          }
        });
        await self.upgradeMarkets(barus);
      }
    });
  },
  methods: {
    async getMarkets() {
      var self = this;
      var res = await thestore.getMarkets(1, 20, "id", "desc", []);

      await self.upgradeMarkets(res.datas);
    },

    async upgradeMarkets(plain_datas) {
      var self = this;

      // data harus kita olah lagi
      var markets = [];
      plain_datas.forEach((ele) => {
        // parsing
        var simbols = ele.pair.split("_");
        ele["pair_show"] = `${simbols[0]}/${simbols[1]}`;
        ele["simbols"] = simbols;

        if (ele.change_24h >= 0) {
          ele["theclass"] = "green";
        } else {
          ele["theclass"] = "red";
        }

        // cegah NaN
        if (ele.change_24h == null) {
          ele["change_24h"] = 0;
        }
        if (ele.ticker_24h_high == null) {
          ele["ticker_24h_high"] = 0;
        }
        if (ele.ticker_24h_low == null) {
          ele["ticker_24h_low"] = 0;
        }

        markets.push(ele);
      });

      self.markets = markets;
    },

    gotoMarket(item) {
      var self = this;
      self.$router.push({
        path: `/markets/${item.id}/${item.pair}`,
      });
    },

    handleDigitMenu() {
      var self = this;

      self.isActive = !self.isActive;
    },

    changeDigitFloat(val) {
      var self = this;

      this.$store.dispatch("changeDigitFloat", val);

      self.sells();
    },
  },
};
</script>

<style scoped>
.table-wide {
  background: none !important;
}
.table-narrow {
  display: none;
}

.d_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.digit_item {
  width: 70px;
  border: 1px solid #708cc7;
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.digit_item span {
  color: aliceblue;
}

.dex_digit {
  position: relative;
  cursor: pointer;
}

.digit_lists {
  position: absolute;
  /* margin-right: 10px; */
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #262c34;
  border: 1px solid #262c34;
  border-radius: 3px;
  width: 70px;
  z-index: 999;

  transition-duration: 0.5s;
}

.menu_active {
  display: flex;
}

.digit_lists span {
  color: aliceblue;
  width: 100%;
  display: flex;
  justify-content: center;
}

.digit_lists span:hover {
  background-color: #708cc7;
}

@media only screen and (max-width: 1024px) {
  .table-wide {
    display: none;
  }
  .table-narrow {
    display: block;
    overflow-y: scroll;
  }
}
</style>
