<!--
  halaman ini hanya bisa diakses ketika status kyc approve
  dan agreement masih false
-->
<template>
  <div class="st_p-wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <!-- bagian text agreement -->
            <div class="row">
              <div
                class="col-12"
                style="background-color: #fff; padding: 15px; max-width: 900px"
              >
                <AgreementText :user="user" />
              </div>
            </div>

            <!-- bagian form agreement -->
            <div class="row mt-4">
              <div class="btn btn-primary" @click="btnAgreementClicked">
                Saya menyetujui Surat Perjanjian diatas
              </div>
            </div>
          </div>

          <!-- modal informasi -->
          <div
            class="modal fade text-dark"
            tabindex="-1"
            aria-hidden="true"
            v-bind:id="`info-modal`"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" style="color: #fff">Informasi</h5>
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    @click="themain.closeModal(`info-modal`)"
                  >
                    <svg
                      data-v-2cdd5bbc=""
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        data-v-2cdd5bbc=""
                        cx="10.5"
                        cy="10.5"
                        r="10.5"
                        fill="#F6465D"
                      ></circle>
                      <path
                        data-v-2cdd5bbc=""
                        d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                        fill="#FAFAFA"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="modal-body">
                  <p style="color: #fff">
                    Selamat, proses KYC Anda telah disetujui.
                  </p>
                  <p style="color: #fff">
                    Sesuai dengan PERATURAN BADAN PENGAWAS PERDAGANGAN BERJANGKA
                    KOMODITI NOMOR 8 TAHUN 2021 TENTANG PEDOMAN PENYELENGGARAAN
                    PERDAGANGAN PASAR FISIK ASET KRIPTO (CRYPTO ASSET) DI BURSA
                    BERJANGKA pasal 24, PT KRIPTO INOVASI NUSANTARA membuat
                    surat perjanjian terlampir pada halaman ini.
                  </p>
                  <p style="color: #fff">
                    Surat Perjanjian Pelanggan Aset kripto ini juga terlampir
                    pada email yang Anda daftarkan.
                  </p>
                  <p style="color: #fff">
                    Mohon dibaca surat perjanjian dibawah ini dan tekan saya
                    menyetujui perjanjian pada bagian paling bawah.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
// import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import AgreementText from "@/components/global/AgreementText.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    AgreementText,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;

      // cegah user ini mengakses jika belum di approve kyc
      if (res.orang_kyc_status == "approve" && res.orang_agreement == false) {
        themain.openModal("info-modal");
      } else {
        // console.log('satu agree');
        // self.$router.push("/");
      }
    } else {
      // console.log('dua agree');
      // self.$router.push("/");
    }
  },

  methods: {
    async btnAgreementClicked() {
      var self = this;
      var res = await thestore.postAgreementUserAgree(self.token);
      // console.log("res agreement", res);
      self.$router.push("/settings/profile");
    },
  },
};
</script>

<style scoped>
.btn-close {
  background: none;
  border: none;
}
</style>
