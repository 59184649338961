<template>
  <div>
    <table class="table my-open-order-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Waktu</th>
          <th>Beli/Jual</th>
          <th>Harga</th>
          <th>{{ simbol_a }}</th>
          <th>{{ simbol_b }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in myobooks" :key="item">
          <td>
            {{ item.id }}
          </td>
          <!-- time -->
          <td>
            {{ moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}
          </td>

          <!-- Buy/Sell -->
          <td>
            <span v-if="item.market_place == 'Limit Buy'" class="green">
              Beli
            </span>
            <span v-if="item.market_place == 'Limit Sell'" class="red">
              Jual
            </span>
          </td>

          <!-- Price -->
          <td>
            <span v-if="item.market_place == 'Limit Buy'" class="green">
              {{
                parseFloat(item.market_position).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </span>
            <span v-if="item.market_place == 'Limit Sell'" class="red">
              {{
                parseFloat(item.market_position).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </span>
          </td>

          <!-- simbol_a -->
          <td>
            <div v-if="item.market_place == 'Limit Buy'">
              {{
                parseFloat(item.market_result_including_fee).numberFormat(
                  currencyAObj.decimal_display
                )
              }}
            </div>
            <div v-else>
              {{
                parseFloat(item.market_amount).numberFormat(
                  currencyAObj.decimal_display
                )
              }}
            </div>
          </td>

          <!-- simbol_b -->
          <td>
            <div v-if="item.market_place == 'Limit Buy'">
              {{
                parseFloat(item.market_amount).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </div>
            <div v-else>
              {{
                parseFloat(item.market_result_including_fee).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </div>
          </td>

          <td>
            <button
              class="btn btn-outline-info btn-sm"
              @click="themain.openModal(`myorders-${item.id}-modal`)"
            >
              Batal
            </button>

            <div
              class="modal fade text-dark"
              tabindex="-1"
              aria-hidden="true"
              v-bind:id="`myorders-${item.id}-modal`"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Konfirmasi</h5>
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      @click="themain.closeModal(`myorders-${item.id}-modal`)"
                      :disabled="modalCloseBtnDisabled"
                    ></button>
                  </div>
                  <div class="modal-body">Apakah Anda yakin?</div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="themain.closeModal(`myorders-${item.id}-modal`)"
                      :disabled="modalCloseBtnDisabled"
                    >
                      Tidak
                    </button>
                    <button
                      type="button"
                      class="btn btn-dark"
                      @click="cancelBuyOrSell(item)"
                    >
                      Ya
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
  },
  data() {
    return {
      themain,
      moment,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      simbol_a: "",
      simbol_b: "",

      myobooks: [],

      modalCloseBtnDisabled: false,
    };
  },

  async created() {
    var self = this;

    // disini kita dengarkan fe-sells dan kita filter jika ada yang mengarah ke kita
    self.emitter.on("fe-sells", async function (e) {
      // console.log("e fe-sells", e);

      // e.data dalam bentuk array orderbook sells
      // Jadi ketikan kita update atau add data, kita dari sini sebaiknya panggil satu per-satu
      // disini kita harus iterasi terlebih dahulu
      e.data.forEach(function (elem) {
        // jika mengarah ke kita, kita harus olah
        if (parseInt(self.usid) == parseInt(elem.orang_id)) {
          self.addOrUpdateMyorders(elem);
        }
      });
    });

    // disini kita dengarkan fe-buys dan kita filter jika ada yang mengarah ke kita
    self.emitter.on("fe-buys", async function (e) {
      // console.log("e fe-buys", e);

      // e.data dalam bentuk array orderbook sells
      // Jadi ketikan kita update atau add data, kita dari sini sebaiknya panggil satu per-satu
      // disini kita harus iterasi terlebih dahulu
      e.data.forEach(function (elem) {
        // jika mengarah ke kita, kita harus olah
        if (parseInt(self.usid) == parseInt(elem.orang_id)) {
          self.addOrUpdateMyorders(elem);
        }
      });
    });

    self.emitter.on("fe-myobook-close", async function (e) {
      var nubooks = [];
      self.myobooks.forEach(function (mbook) {
        if (mbook.id != e.data.id) {
          nubooks.push(mbook);
        }
      });
      self.myobooks = nubooks;
    });
  },

  async mounted() {
    var self = this;

    // simbols
    var simbols = self.marketData.pair.split("_");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];

    // jika ada token, mita ambil myorderbook
    if (self.token != null && self.token != "") {
      await self.getMyOrders();
    }
  },

  methods: {
    async getMyOrders() {
      var self = this;
      var filter_rules = [
        {
          field: "market_pair",
          op: "equal-for-pair",
          value: self.marketData.pair,
        },
      ];
      // console.log("self.mytoken", self.token);
      var myobooksRes = await thestore.getMyOrderBooksOpen(
        self.token,
        1,
        20,
        "updated_at",
        "desc",
        filter_rules
      );
      // console.log("myobooksRes", myobooksRes);

      self.myobooks = myobooksRes.datas;
    },

    /**
     * Memasukkan data yang baru atau update yang datang dari emit frontend
     * @param {*} newDataObj
     */
    addOrUpdateMyorders(newDataObj) {
      var self = this;

      var my_orders_new = [];

      // mengganti data yang baru masuk
      var apa_ada = 0;
      self.myobooks.forEach((ele) => {
        if (ele.id == newDataObj.id) {
          my_orders_new.push(newDataObj);
          apa_ada = apa_ada + 1;
        } else {
          my_orders_new.push(ele);
        }
      });

      // jika tidak ada data yang di update, maka dimasukkan saja data baru
      if (apa_ada == 0) {
        my_orders_new.unshift(newDataObj);
      }

      // ganti myobooks
      self.myobooks = my_orders_new;
    },

    async cancelBuyOrSell(myorderObj) {
      var self = this;

      var res = await thestore.postCancelOrder(self.token, myorderObj.id);
      themain.closeModal(`myorders-${myorderObj.id}-modal`);
      // console.log("res", res);
      if (res.success == true) {
        var olds = self.myobooks;
        var news = [];

        // remove data
        olds.forEach(function (el) {
          if (el.id != myorderObj.id) {
            news.push(el);
          }
        });
        self.myobooks = news;
      } else {
        alert(self.msg);
      }
    },
  },
};
</script>

<style>
.my-open-order-table thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-open-order-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-open-order-table td,
th {
  padding: 5px;
  text-align: right;
}
</style>
