<template>
  <div class="ps_container">
    <div class="container">
      <h2>Aset Populer</h2>
      <market-table />
    </div>
  </div>
</template>

<script>
// components
import MarketTable from "../global/MarketTable.vue";

export default {
  components: {
    MarketTable,
  },
};
</script>

<style scoped>
.ps_container {
  background-image: url("/images/popular-bg-02.png");
  background-size: cover;
  /* height: 800px; */
  /* padding-top: 70px; */
  padding: 68px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
</style>
