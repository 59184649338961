<template>
  <div class="card_itm">
    <img :src="`/images/Iklan-1.png`" alt="iklan" />
  </div>
</template>

<script>
export default {
  props: {
    num: Number,
  },
};
</script>

<style scoped>
.card_itm img {
  width: 1084.966px;
  height: 240px;
}

@media only screen and (max-width: 500px) {
  .card_itm img {
    height: 160px;
  }
}
</style>
