<template>
  <div>
    <TopNav />
    <div class="tnc_head">
      <span>Syarat, Ketentuan dan Tata Tertib Perdagangan</span>
    </div>
    <div class="container text-white">
      <div class="none_t"></div>
      <!-- <div class="tnc_menu">
        <router-link to="/" class="menu_i">Beranda</router-link>
        <router-link to="/pages/term-and-conditions" class="menu_i"
          >Syarat & Ketentuan</router-link
        >
        <router-link to="/p/faq" class="menu_i">FAQ</router-link>
      </div> -->
      <TermConditions />
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules

// applibs

import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import TermConditions from "@/components/global/TermConditions.vue";

export default {
  components: {
    TopNav,
    Footer,
    TermConditions,
  },
  data() {
    return {
      web_domain: "coinx.co.id",
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  gap: 30px;
}
h2 {
  font-size: 2.5rem;
}
h5.title {
  margin-top: 3rem;
  font-size: 2rem;
}

.tnc_head {
  width: 100%;
  height: 100px;
  background-color: #3c1e3d;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 20px;
}

.tnc_head span {
  font-size: 20px;
  font-weight: 600;
  color: #f0f3f7;
}

.tnc_menu {
  position: -webkit-sticky;
  position: fixed !important;
  top: 25%;
  left: 15%;
  display: flex;
  flex-direction: column;
}

.menu_i {
  padding: 3px 10px;
  border: 1px solid #e0e0e0;
  color: #f0f3f7;
}

.menu_i:hover {
  background-color: #9c9c9c;
}

.none_t {
  width: 150px;
}

@media only screen and (max-width: 1527px) {
  .tnc_menu {
    left: 5%;
  }
}

@media only screen and (max-width: 1171px) {
  .tnc_menu,
  .none_t {
    display: none;
  }
}
</style>
