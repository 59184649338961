const Deposit = [
  {
    id: 21,
    orang_id: 5,
    ccurrency_symbol: "IDR",
    saldo_address: null,
    saldo_value: "86472755289.57454",
    ccurrency_obj: {
      id: 1,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: true,
      ccurrency_kind: "fiat",
      ccurrency_name: "Rupiah",
      ccurrency_ordernum: null,
      ccurrency_show: "yes",
      ccurrency_symbol: "IDR",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "0.5",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d4ea9cb82ef78f4f7c01be735954086fbb9b21ce/idr-icon.jpeg",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "rupiah",
      decimal_display: 2,
      ccurrency_config: {
        address_view: "",
        deposit_info_view: "",
        deposit_manual_view: "",
      },
      wd_config: null,
      travel_rules_limit: "0.0",
      created_at: "2024-01-24T00:42:23.093Z",
      updated_at: "2024-01-29T13:59:00.161Z",
    },
    saldo_lock: "164455867.48688212078",
    created_at: "2024-01-27T23:22:11.095Z",
    updated_at: "2024-04-24T12:29:21.792Z",
  },
  {
    id: 22,
    orang_id: 5,
    ccurrency_symbol: "BTC",
    saldo_address: null,
    saldo_value: "9.275891258066895323",
    ccurrency_obj: {
      id: 2,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: true,
      ccurrency_kind: "cryptocurrency",
      ccurrency_name: "Bitcoin",
      ccurrency_ordernum: null,
      ccurrency_show: "yes",
      ccurrency_symbol: "BTC",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0d966faefb966677b8122711b659931c55a5ac57/btc-icon.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "bitcoin",
      decimal_display: 8,
      ccurrency_config: {
        address_view: "",
        deposit_info_view: "",
        deposit_manual_view: "",
      },
      wd_config: ["Bitcoin"],
      travel_rules_limit: "0.0",
      created_at: "2024-01-24T00:42:23.224Z",
      updated_at: "2024-01-28T00:32:15.667Z",
    },
    saldo_lock: "0.1492783201797134158",
    created_at: "2024-01-27T23:22:11.126Z",
    updated_at: "2024-04-24T12:29:32.255Z",
  },
  {
    id: 23,
    orang_id: 5,
    ccurrency_symbol: "ETH",
    saldo_address: null,
    saldo_value: "19609.5337485386709703",
    ccurrency_obj: {
      id: 3,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: true,
      ccurrency_kind: "evm_coin",
      ccurrency_name: "Ethereum",
      ccurrency_ordernum: null,
      ccurrency_show: "yes",
      ccurrency_symbol: "ETH",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f32ec08a984f89600df66a0bf4919ec0b7c3a5cb/eth-icon.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "ethereum",
      decimal_display: 6,
      ccurrency_config: {
        address_view: "EvmAddress",
        deposit_info_view: "EvmEthInfo",
        deposit_manual_view: "EvmCoinETHDepositManual",
      },
      wd_config: ["EVMCoin"],
      travel_rules_limit: "0.0",
      created_at: "2024-01-24T00:42:23.316Z",
      updated_at: "2024-01-28T02:23:37.088Z",
    },
    saldo_lock: "387.521256301313265883",
    created_at: "2024-01-27T23:22:11.145Z",
    updated_at: "2024-02-11T13:52:00.428Z",
  },
  {
    id: 24,
    orang_id: 5,
    ccurrency_symbol: "XRP",
    saldo_address: null,
    saldo_value: "0.0",
    ccurrency_obj: {
      id: 4,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: false,
      ccurrency_kind: "cryptocurrency",
      ccurrency_name: "Ripple",
      ccurrency_ordernum: null,
      ccurrency_show: "no",
      ccurrency_symbol: "XRP",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/images/default-currency.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: null,
      decimal_display: 8,
      ccurrency_config: null,
      wd_config: null,
      travel_rules_limit: "0.0",
      created_at: "2024-01-24T00:42:23.467Z",
      updated_at: "2024-01-24T00:42:23.467Z",
    },
    saldo_lock: 0,
    created_at: "2024-01-27T23:22:11.156Z",
    updated_at: "2024-01-27T23:22:11.156Z",
  },
  {
    id: 25,
    orang_id: 5,
    ccurrency_symbol: "BNB",
    saldo_address: null,
    saldo_value: "6282.472644816572",
    ccurrency_obj: {
      id: 5,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: true,
      ccurrency_kind: "evm_coin",
      ccurrency_name: "Binance Coin",
      ccurrency_ordernum: null,
      ccurrency_show: "yes",
      ccurrency_symbol: "BNB",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--671ee8ea6c377f0a88df4c68ba7d3ae4f75a2237/bnb-icon.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "ethereum",
      decimal_display: 8,
      ccurrency_config: {
        address_view: "EvmAddress",
        deposit_info_view: "EvmMainInfo",
        deposit_manual_view: "EvmCoinBNBDepositManual",
      },
      wd_config: ["EVMCoin"],
      travel_rules_limit: "0.0",
      created_at: "2024-01-24T00:42:23.560Z",
      updated_at: "2024-01-28T14:44:05.339Z",
    },
    saldo_lock: "4107.902505862038305949",
    created_at: "2024-01-27T23:22:11.169Z",
    updated_at: "2024-02-11T13:52:14.996Z",
  },
  {
    id: 70,
    orang_id: 5,
    ccurrency_symbol: "ADA",
    saldo_address: null,
    saldo_value: "9763487.362545813",
    ccurrency_obj: {
      id: 6,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: false,
      ccurrency_kind: "cryptocurrency",
      ccurrency_name: "Cardano",
      ccurrency_ordernum: null,
      ccurrency_show: "yes",
      ccurrency_symbol: "ADA",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2b6655fe41c38a5e3c1d7b59c07097a1d8031a5c/cardano-icon.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "ethereum",
      decimal_display: 8,
      ccurrency_config: {
        address_view: "",
        deposit_info_view: "",
        deposit_manual_view: "",
      },
      wd_config: null,
      travel_rules_limit: "0.0",
      created_at: "2024-01-29T00:58:48.601Z",
      updated_at: "2024-01-29T00:59:23.928Z",
    },
    saldo_lock: "342862.746426694386228",
    created_at: "2024-01-30T05:40:19.497Z",
    updated_at: "2024-02-11T13:52:12.355Z",
  },
  {
    id: 107,
    orang_id: 5,
    ccurrency_symbol: "DOGE",
    saldo_address: null,
    saldo_value: "0.0",
    ccurrency_obj: {
      id: 7,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: false,
      ccurrency_kind: "evm_token",
      ccurrency_name: "Dogecoin",
      ccurrency_ordernum: null,
      ccurrency_show: "yes",
      ccurrency_symbol: "DOGE",
      ccurrency_wallet_status: "active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--874ead81e122ac143b604bc2d1e5832146cc62b0/dogeicon.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "ethereum",
      decimal_display: 8,
      ccurrency_config: {
        address_view: "EvmAddress",
        deposit_info_view: "EvmTokenInfo",
        deposit_manual_view: "EvmTokenDepositManual",
      },
      wd_config: ["EVMToken"],
      travel_rules_limit: "0.0",
      created_at: "2024-03-29T05:43:12.603Z",
      updated_at: "2024-03-29T05:54:39.474Z",
    },
    saldo_lock: 0,
    created_at: "2024-04-04T02:51:36.909Z",
    updated_at: "2024-04-04T02:51:36.909Z",
  },
  {
    id: 110,
    orang_id: 5,
    ccurrency_symbol: "JMB",
    saldo_address: null,
    saldo_value: "0.0",
    ccurrency_obj: {
      id: 8,
      ccurrency_available_deposit: true,
      ccurrency_availablewd: false,
      ccurrency_kind: "evm_token",
      ccurrency_name: "Jambu",
      ccurrency_ordernum: null,
      ccurrency_show: "no",
      ccurrency_symbol: "JMB",
      ccurrency_wallet_status: "not active",
      ccurrency_withdraw_fee: "100.0",
      ccurrency_icon_data_url:
        "https://api.coinx.co.id/images/default-currency.png",
      ccurrency_wd_min_in_idr: "1000000.0",
      ccurrency_wallet_group: "ethereum",
      decimal_display: 8,
      ccurrency_config: {
        address_view: "EvmAddress",
        deposit_info_view: "EvmTokenInfo",
        deposit_manual_view: "EvmTokenDepositManual",
      },
      wd_config: ["EVMToken"],
      travel_rules_limit: "0.0",
      created_at: "2024-04-16T02:50:30.756Z",
      updated_at: "2024-04-16T03:06:56.133Z",
    },
    saldo_lock: 0,
    created_at: "2024-04-16T02:50:58.126Z",
    updated_at: "2024-04-16T02:50:58.126Z",
  },
];

export default Deposit;
