<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto glass_a">
        <form @submit.prevent="submitForm">
          <span>Ubah Password</span>
          <div class="form-group" style="display: none">
            <input type="text" v-model="name" />
          </div>
          <div class="form-group" style="display: none">
            <input type="text" v-model="email" />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              ref="password"
              v-model="password"
            />
            <TogglePassword @is-password-show="togglePasswordValue" />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Ulangi Password"
              ref="rePassword"
              v-model="password_confirm"
            />
            <TogglePassword @is-password-show="toggleRePasswordValue" />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>

          <button type="submit" class="btn btn_gradient">
            <span v-if="!loading" class="m-0" style="font-size: 1rem"
              >Kirim</span
            >
            <span
              v-else
              class="spinner-border spinner-border-sm mx-auto my-0"
              aria-hidden="true"
            ></span>
          </button>
        </form>

        <!-- errr response -->
        <div v-show="isError" class="err_box">
          <p class="m-0 text-center">{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <Footer />

    <teleport to="body">
      <ModalSuccess :show="showModalRes" @close="showModalRes = false">
        <template #body>
          <SuccessAnimation />
          <p style="text-align: center">
            {{ resMsg }}
          </p>
        </template>
      </ModalSuccess>
    </teleport>
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import ModalSuccess from "@/components/modals/ModalSuccess.vue";
import SuccessAnimation from "@/components/animations/SuccessAnimation.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";

export default {
  components: {
    TopNav,
    Footer,
    VueRecaptcha,
    ModalSuccess,
    SuccessAnimation,
    TogglePassword,
  },
  data() {
    return {
      themain,
      name: "",
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: "",
      password_confirm: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",

      isError: false,
      errorMessage: null,
      showModalRes: false,
      resMsg: null,
    };
  },

  methods: {
    async submitForm() {
      var self = this;
      this.loading = true;

      if (self.recaptchaToken != "") {
        if (self.password == self.password_confirm) {
          var res = await thestore.postAuthChangePassword(
            self.email,
            self.token,
            self.password,
            self.password_confirm,
            self.recaptchaToken
          );
          if (res.success == true) {
            self.showModalRes = true;
            self.resMsg = res.msg;
            setTimeout(() => {
              self.$router.push("/auth/login");
            }, 3000);
            // alert(res.msg);
          } else {
            self.isError = true;
            self.errorMessage = res.msg;
            // alert(res.msg);
            if (res.msg == "Wrong captcha") {
              setTimeout(() => {
                self.$router.go();
              }, 2000);
            }
          }
        } else {
          self.isError = true;
          self.errorMessage = "Password confirmation is not the same";
          // alert("Password confirmation is not the same");
        }
      }

      this.loading = false;
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },

    toggleRePasswordValue(val) {
      const rePassword = this.$refs.rePassword;
      rePassword.type = val ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.btn_gradient {
  background-color: #708cc7 !important;
  color: #fff;
}

.btn_gradient:hover {
  background-color: #5572b1 !important;
}

.glass_a {
  /* From https://css.glass */
  background: linear-gradient(
    114deg,
    rgba(121, 67, 239, 0.18) 0%,
    rgba(108, 36, 161, 0.29) 36%,
    rgba(33, 93, 154, 0.28) 72%,
    rgba(146, 105, 197, 0.43) 100%
  );

  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px 0;
}

.form-control {
  color: black !important;
}

.err_box {
  max-width: 340px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: palevioletred;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 30px;
}

@media only screen and (min-width: 768px) {
  .glass_a {
    padding: 30px;
  }
}
</style>
